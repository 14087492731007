import { useState } from 'react'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


const InspectionCreation = ({ inputState, handleInputChange, handleClose, handleBack, handleSubmit }) => {
  const canSubmit = () => {
    return inputState.date !== undefined && inputState.date !== "" &&
      inputState.ventil_reference !== undefined && inputState.ventil_reference !== "" &&
      inputState.ventil_tech !== undefined && inputState.ventil_tech !== "" &&
      inputState.company_name !== undefined && inputState.company_name !== "" &&
      inputState.location !== undefined && inputState.location !== "" &&
      inputState.contact_name !== undefined && inputState.contact_name !== "" &&
      inputState.contact_email !== undefined && inputState.contact_email !== "" &&
      inputState.service_contract !== undefined && inputState.service_contract !== "" &&

      inputState.machine_number !== undefined && inputState.machine_number !== "" &&
      inputState.machine_model !== undefined && inputState.machine_model !== "",
      inputState.workflow_name !== undefined && inputState.workflow_name !== ""
  }

  if (inputState.workflow_name === undefined) {
    inputState.workflow_name = "VENTIL EQUIPMENT ITP PROJECT"
  }


  return <div>
    <DialogTitle>Create New Inspection Project</DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{ mb: 2 }}>
        We need some additional information in order to create a new project.
      </DialogContentText>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          required
          label="date"
          name="date"
          value={inputState.date ? dayjs(inputState.date) : undefined}
          onChange={(newValue) => {

            const payload = {
              target: {
                value: newValue.toDate().toLocaleDateString()
              }
            }

            handleInputChange("date", payload);

          }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>

      <InputField
        required
        value={inputState.workflow_name}
        title="Workflow Name"
        name="workflow_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_reference}
        title="Ventil Reference"
        name="ventil_reference"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_tech}
        title="Ventil Technician"
        name="ventil_tech"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.company_name}
        title="Company Name"
        name="company_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.location}
        title="Location"
        name="location"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_name}
        title="Contact Name"
        name="contact_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_email}
        title="Contact Email"
        name="contact_email"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.service_contract}
        title="Service Contract"
        name="service_contract"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_number}
        title="Machine Number"
        name="machine_number"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_model}
        title="Machine Model"
        name="machine_model"
        handleInputChange={handleInputChange}
      />

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleBack}>Back</Button>
      <Button disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
    </DialogActions>
  </div>
}

function dateToISOLikeButLocal(date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19);
  return isoLocal;
}

const HealthCheckCreation = ({ inputState, handleInputChange, handleClose, handleBack, handleSubmit }) => {
  const canSubmit = () => {
    return inputState.date !== undefined && inputState.date !== "" &&
      inputState.ventil_reference !== undefined && inputState.ventil_reference !== "" &&
      inputState.ventil_tech !== undefined && inputState.ventil_tech !== "" &&
      inputState.company_name !== undefined && inputState.company_name !== "" &&
      inputState.location !== undefined && inputState.location !== "" &&
      inputState.contact_name !== undefined && inputState.contact_name !== "" &&
      inputState.contact_email !== undefined && inputState.contact_email !== "" &&
      inputState.service_contract !== undefined && inputState.service_contract !== "" &&

      inputState.machine_number !== undefined && inputState.machine_number !== "" &&
      inputState.machine_model !== undefined && inputState.machine_model !== "",
      inputState.workflow_name !== undefined && inputState.workflow_name !== ""
  }

  if (inputState.workflow_name === undefined) {
    inputState.workflow_name = "VENTIL EQUIPMENT HEALTH CHECK"
  }

  return <div>
    <DialogTitle>Create New Health Check Project</DialogTitle>
    <DialogContent>
      <DialogContentText color="text.primary" sx={{ mb: 2 }}>
        We need some additional information in order to create a new project.
      </DialogContentText>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          required
          label="date"
          name="date"
          value={dayjs(inputState.date)}
          onChange={(newValue) => {
            const payload = {
              target: {
                value: newValue.toDate().toLocaleDateString()
              }
            }

            handleInputChange("date", payload);

          }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>

      <InputField
        required
        value={inputState.workflow_name}
        title="Workflow Name"
        name="workflow_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_reference}
        title="Ventil Reference"
        name="ventil_reference"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_tech}
        title="Ventil Technician"
        name="ventil_tech"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.company_name}
        title="Company Name"
        name="company_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.location}
        title="Location"
        name="location"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_name}
        title="Contact Name"
        name="contact_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_email}
        title="Contact Email"
        name="contact_email"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.service_contract}
        title="Service Contract"
        name="service_contract"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_number}
        title="Machine Number"
        name="machine_number"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_model}
        title="Machine Model"
        name="machine_model"
        handleInputChange={handleInputChange}
      />

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleBack}>Back</Button>
      <Button disabled={!canSubmit()} onClick={handleSubmit}>Submit</Button>
    </DialogActions>
  </div>
}


const InputField = ({ title, name, value, required, handleInputChange, type = "text" }) =>
  <TextField
    sx={{ mb: 1 }}
    required={required}
    margin="dense"
    id={name}
    label={title}
    type={type}
    fullWidth
    variant="standard"
    value={value}
    onChange={(e) => handleInputChange(name, e)}
  />


const ProjectCreateDialog = ({ open, onClose }) => {
  const [inputState, setInputState] = useState({
    date: "",
    client_name: "",
    machine_number: "",
    machine_model: "",
    service_engineer: "",
  });

  const handleClose = () => {
    onClose(undefined)
    setInputState({})
  }

  const handleBack = () => {
    setInputState({})
  }

  const handleSubmit = () => {
    onClose(inputState)
    setInputState({})
  }

  const handleInputChange = (input, event) => {
    setInputState((prevState) => ({ ...prevState, ...{ [input]: event.target.value } }))
  }

  const startInspectionProject = () => {
    setInputState((prevState) => ({ ...prevState, ...{ "inspectionProject": true } }))
  }

  const startHealthCheckProject = () => {
    setInputState((prevState) => ({ ...prevState, ...{ "healthCheckProject": true } }))
  }

  const ProjectCreateButton = ({ text, onClick }) => {
    return <Button
      variant="outlined"
      onClick={onClick} >{text}</Button>
  }

  const ProjectSelectScreen = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 1.5, p: 4, pt: 3 }}>
        <Typography sx={{ mb: 3 }}><b>Create Project</b></Typography>
        <ProjectCreateButton text="Inspection Project" onClick={() => { startInspectionProject() }} />
        or
        <ProjectCreateButton text="Health Check Project" onClick={() => { startHealthCheckProject() }} />
      </Box>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ elevation: 0, variant: "outlined" }} >
      {inputState?.healthCheckProject !== true && inputState?.inspectionProject !== true && <ProjectSelectScreen />}

      {inputState?.healthCheckProject && <HealthCheckCreation handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={handleClose} inputState={inputState} handleBack={handleBack} />}
      {inputState?.inspectionProject && <InspectionCreation handleInputChange={handleInputChange} handleSubmit={handleSubmit} handleClose={handleClose} inputState={inputState} handleBack={handleBack} />}
    </Dialog>
  )
}

export default ProjectCreateDialog;