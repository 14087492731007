import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


import { getSessionChecklistOverview } from '../../util/api';
import { updateSessionInfo } from '../../util/api/session';
import { useDispatch } from 'react-redux';
import { setError } from '../../actions/notificationActions';


const InputField = ({ title, name, value, required, handleInputChange, type = "text" }) =>
  <TextField
    sx={{ mb: 1 }}
    required={required}
    margin="dense"
    id={name}
    label={title}
    type={type}
    fullWidth
    variant="standard"
    value={value}
    onChange={(e) => handleInputChange(name, e)}
  />

const HealthCheckUpdate = ({ inputState, handleInputChange, }) => {
  return <div>
    <DialogContent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          required
          label="date"
          name="date"
          value={dayjs(inputState.date)}
          onChange={(newValue) => {
            const payload = {
              target: {
                value: newValue.toDate().toLocaleDateString()
              }
            }

            handleInputChange("date", payload);

          }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>

      <InputField
        required
        value={inputState.workflow_name ? inputState.workflow_name : ""}
        title="Workflow Name"
        name="workflow_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_reference ? inputState.ventil_reference : ""}
        title="Ventil Reference"
        name="ventil_reference"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.ventil_tech ? inputState.ventil_tech : ""}
        title="Ventil Technician"
        name="ventil_tech"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.company_name ? inputState.company_name : ""}
        title="Company Name"
        name="company_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.location ? inputState.location : ""}
        title="Location"
        name="location"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_name ? inputState.contact_name : ""}
        title="Contact Name"
        name="contact_name"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.contact_email ? inputState.contact_email : ""}
        title="Contact Email"
        name="contact_email"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.service_contract ? inputState.service_contract : ""}
        title="Service Contract"
        name="service_contract"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_number ? inputState.machine_number : ""}
        title="Machine Number"
        name="machine_number"
        handleInputChange={handleInputChange}
      />

      <InputField
        required
        value={inputState.machine_model ? inputState.machine_model : ""}
        title="Machine Model"
        name="machine_model"
        handleInputChange={handleInputChange}
      />

    </DialogContent>
  </div>
}



const ProjectInfoSettings = ({ projectID }) => {

  const [clientName, setClientName] = useState("")
  const [machineNumber, setMachineNumber] = useState("")
  const [machineModel, setMachineModel] = useState("")
  const [serviceEngineer, setServiceEngineer] = useState("")
  const [projectDate, setProjectDate] = useState("")

  const [inputState, setInputState] = useState({});

  const canSubmit = () => {
    return true;
    return inputState.date !== undefined && inputState.date !== "" &&
      inputState.ventil_reference !== undefined && inputState.ventil_reference !== "" &&
      inputState.ventil_tech !== undefined && inputState.ventil_tech !== "" &&
      inputState.company_name !== undefined && inputState.company_name !== "" &&
      inputState.location !== undefined && inputState.location !== "" &&
      inputState.contact_name !== undefined && inputState.contact_name !== "" &&
      inputState.contact_email !== undefined && inputState.contact_email !== "" &&
      inputState.service_contract !== undefined && inputState.service_contract !== "" &&

      inputState.machine_number !== undefined && inputState.machine_number !== "" &&
      inputState.machine_model !== undefined && inputState.machine_model !== "",
      inputState.workflow_name !== undefined && inputState.workflow_name !== ""
  }

  const setHealthCheckProjectInfo = (data) => {
    let newState = inputState;

    newState.date = data.date;
    newState.ventil_reference = data.ventil_reference
    newState.ventil_tech = data.ventil_tech
    newState.company_name = data.company_name
    newState.location = data.location
    newState.contact_name = data.contact_name
    newState.contact_email = data.contact_email
    newState.service_contract = data.service_contract
    newState.machine_number = data.machine_number
    newState.machine_model = data.machine_model
    newState.workflow_name = data.workflow_name

    console.log(data.workflow_name)

    setInputState((prevState) => ({ ...newState, }))
  }

  const dispatch = useDispatch();


  const fetchProjectInfo = () => {
    getSessionChecklistOverview(projectID).then((res) => {

      setHealthCheckProjectInfo(res.data);
    })
  }

  useEffect(() => {
    if (projectID !== null) {
      fetchProjectInfo();
    }
  }, [projectID])

  const handleUpdate = () => {
    const payload = {
      date: inputState.date,
      ventil_reference: inputState.ventil_reference,
      ventil_tech: inputState.ventil_tech,
      company_name: inputState.company_name,
      location: inputState.location,
      contact_name: inputState.contact_name,
      contact_email: inputState.contact_email,
      service_contract: inputState.service_contract,
      machine_number: inputState.machine_number,
      machine_model: inputState.machine_model,
      workflow_name: inputState.workflow_name,
    }

    updateSessionInfo(projectID, payload).then(() => {
      window.location.reload()
    }).catch((e) => {

      dispatch(setError(e?.response?.data?.detail));
    })
  }

  const handleInputChange = (input, event) => {
    setInputState((prevState) => ({ ...prevState, ...{ [input]: event.target.value } }))
  }

  return (
    <Card sx={{ borderRadius: 0.5, marginTop: 0 }}>

      <CardHeader
        title="Project Info"
        subheader={"Here you edit the information of your project."}
        subheaderTypographyProps={{
          color: "#dddddd",
          variant: "body2",
          fontSize: "12px"
        }}

        action={
          <Button variant="outlined" disabled={!canSubmit()} onClick={handleUpdate}>
            Update
          </Button>
        }
      />
      <Divider sx={{ margin: 2, marginBottom: 0 }} />
      <CardContent sx={{ alignItems: "left", display: "flex", flexDirection: "column" }}>

        <HealthCheckUpdate handleInputChange={handleInputChange} inputState={inputState} />
      </CardContent>

    </Card>
  )
}

export default ProjectInfoSettings;